import { useState, useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "components/Button";
import Accordion from "components/Accordion";
import NoDataMessage from "components/NoDataMessage";
import SkeletonLoader from "components/SkeletonLoader";
import { getCurrentLanguage } from "common/utils";
import { isNullOrUndefined } from "common/helpers";
import defaultImg from "assets/images/general/avatar.png";
import { getNineBoxList as getCurrentTalentScore } from "redux/actions/talentScore/talentScoreActions";
import { getImageURL } from "helpers/presignedUrl";
import { useNineBoxTypes } from "hooks/useNineBoxType";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import HistoricalEmployee from "../../HistoricalEmployee";
import { calculateTotal, getDataNineBoxResult } from "../../../functions";
import NineBoxTypeForm from "../../NineBoxTypeForm";
import {
  StyledDescription,
  StyledQuadrantDescription,
  StyledHistorical,
} from "../styles";

const HistoricalSmallDataSet = (props) => {
  const {
    t,
    quadrantResult,
    quadrants,
    isAllData,
    isAdmin,
    resetQuadrants,
    cleanedQuery,
    resultScaleReducerStates,
    dispatch,
  } = props;

  const { isLoadingScaleList, resultScaleList } = resultScaleReducerStates;
  const language = getCurrentLanguage();

  const quadrantNumber = quadrantResult && quadrantResult[16] ? quadrantResult[16] : 0;
  const total = calculateTotal(quadrantNumber, quadrants, true);
  const cleanedQueryWithQuadrant = { ...cleanedQuery };

  if (quadrantNumber > 0) {
    cleanedQueryWithQuadrant.q = {
      ...cleanedQuery.q,
      nine_box_type_quadrant_number_eq: quadrantNumber,
    };
  }

  const { nineBoxTypeList, isLoading } = useNineBoxTypes(t);

  const [editNineBox, setEditNineBox] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [quadrantData, setQuadrantData] = useState({});
  const [historical, setHistorical] = useState([]);

  const {
    data: { data: currentTalentScoreList } = {},
    isLoading: isLoadingCurrentTalentScoreList,
  } = useQuery(
    ["currentTalentScoreList", cleanedQueryWithQuadrant],
    () => getCurrentTalentScore(cleanedQueryWithQuadrant, 1, total),
    {
      staleTime: Infinity,
      enabled: quadrantNumber > 0,
    },
  );

  const getData = useCallback(async () => {
    if (!currentTalentScoreList || currentTalentScoreList.length === 0) return;

    const results = await getDataNineBoxResult(
      currentTalentScoreList,
      resultScaleList,
      t,
      nineBoxTypeList,
    );

    const filteredDataByManagerAnswered = results.filter(
      (result) => result.hasManagerEvaluationAnswered,
    );

    const data = isAllData ? results : filteredDataByManagerAnswered;

    if (!isNullOrUndefined(data)) {
      const historicalResult = await Promise.all(
        data.map(async (result) => {
          let profileImage = result?.img || defaultImg;
          if (result?.imgKey) {
            try {
              profileImage = await getImageURL(result.imgKey);
            } catch (error) {
              profileImage = defaultImg;
            }
          }

          return {
            id: result.id,
            src: profileImage,
            title: result.name,
            customRender: <div data={ result }>{""}</div>,
          };
        }),
      );

      setHistorical(historicalResult);
    }
  }, [currentTalentScoreList, resultScaleList, t, nineBoxTypeList, isAllData]);

  useEffect(() => {
    if (!isLoadingCurrentTalentScoreList && currentTalentScoreList) {
      getData();
    }
    // eslint-disable-next-line
  }, [currentTalentScoreList]);

  useEffect(() => {
    setHistorical([]);
    // eslint-disable-next-line
  }, [quadrantNumber]);

  const handleSelectedPanel = (panel) => {
    if (panel?.id) {
      const employeeData = historical.find((item) => item.id === panel.id);

      employeeData.customRender = (
        <HistoricalEmployee
          data={ panel }
          t={ t }
          dispatch={ dispatch }
          resultScaleList={ resultScaleList }
          isLoadingScaleList={ isLoadingScaleList }
        />
      );
    }
  };

  const handleCancelEdit = (updatedNineBoxType = null) => {
    if (updatedNineBoxType) {
      setQuadrantData(updatedNineBoxType);
    }

    setIsChecked(false);
    setEditNineBox(false);
  };

  const handleCheckboxChange = (event) => {
    const isEditChecked = event.target.checked;
    setIsChecked(isEditChecked);

    if (isEditChecked && quadrantNumber > 0) {
      const selectedNineBox = nineBoxTypeList?.find(
        (box) => box.quadrant_number === quadrantNumber,
      );
      setEditNineBox(selectedNineBox || false);
    } else {
      setEditNineBox(false);
    }
  };

  useEffect(() => {
    setIsChecked(false);
    setEditNineBox(false);
    if (quadrantNumber > 0 && !isLoading) {
      const selectedQuadrant = nineBoxTypeList?.find(
        (box) => box.quadrant_number === quadrantNumber,
      );
      setQuadrantData(selectedQuadrant || {});
    }
  }, [quadrantNumber, isLoading, nineBoxTypeList]);

  return (
    <>
      <StyledDescription square>
        <Typography variant={ "h4" }>
          {`${calculateTotal(quadrantNumber, quadrants, isAllData)} ${t("ninebox:historical_total")}`}
        </Typography>
        <Typography variant={ "h6" }>
          {`${t("ninebox:quadrant")}: ${
            quadrantData?.quadrant_number
              ? `${quadrantData.quadrant_number}. ${quadrantData[`name_${language}`]}`
              : t("ninebox:all_quadrant")
          }`}
        </Typography>
        <StyledQuadrantDescription variant={ "body1" }>
          {quadrantData?.description
            ? `${quadrantData[`description_${language}`]}`
            : t("ninebox:description")}
        </StyledQuadrantDescription>
        {!quadrantData?.description && (
          <StyledQuadrantDescription variant={ "body1" }>
            {t("ninebox:text_details")}
          </StyledQuadrantDescription>
        )}
        <Button
          variant={ "contained" }
          typeStyle={ "submit" }
          isDisabled={ quadrantData?.quadrant_number === 0 || editNineBox }
          onClick={ resetQuadrants }
        >
          {t("ninebox:view_all")}
        </Button>
        {quadrantNumber > 0 && isAdmin && (
          <>
            <FormControlLabel
              disabled={ isLoading }
              control={ <Checkbox checked={ isChecked } onChange={ handleCheckboxChange } /> }
              label={ t("ninebox:quadrant_form.title") }
            />
            {editNineBox && (
              <NineBoxTypeForm
                t={ t }
                nineBoxType={ editNineBox }
                handleCancelEdit={ handleCancelEdit }
              />
            )}
          </>
        )}
      </StyledDescription>
      {isLoadingCurrentTalentScoreList
        && <SkeletonLoader numberOfSkeletons={ 4 } isInline={ false } />}
      {!editNineBox && historical.length > 0 && (
        <StyledScrollBar maxHeight={ "400px" }>
          <StyledHistorical>
            <Accordion
              isPadding
              isDefaultIconStyle
              type={ "square" }
              data={ historical }
              setSelectedPanel={ handleSelectedPanel }
            />
          </StyledHistorical>
        </StyledScrollBar>
      )}
      {!isLoadingCurrentTalentScoreList
        && historical.length === 0 && quadrantData?.quadrant_number && (
        <NoDataMessage
          message={ quadrantData?.emptyDataMessage || t("ninebox:historical_empty") }
        />
      )}
    </>
  );
};

HistoricalSmallDataSet.propTypes = {
  t: PropTypes.func.isRequired,
  resetQuadrants: PropTypes.func.isRequired,
  cleanedQuery: PropTypes.object.isRequired,
  resultScaleReducerStates: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  quadrantResult: PropTypes.any,
  quadrants: PropTypes.any,
  isAllData: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

HistoricalSmallDataSet.defaultProps = {
  quadrantResult: null,
  quadrants: null,
  isAllData: true,
  isAdmin: false,
};

export default HistoricalSmallDataSet;
