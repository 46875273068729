import { Tooltip } from "react-svg-tooltip";
import lte from "lodash/lte";
import gte from "lodash/gte";
import reduce from "lodash/reduce";
import ListItemText from "@mui/material/ListItemText";
import {
  isNullOrUndefined,
  orderByDesc,
} from "common/helpers";
import { formatDateMonthYear, getPercent, findDataInScale } from "common/utils";
import {
  NINEBOX,
  PATTERN_OBJECT_BOUNDING,
  ACTIONS,
  SIZE,
  OBJECT_KEYS,
} from "common/constants";
import { CIRCLE_SIZE } from "common/constants/ninebox";
import { getImageURL } from "helpers/presignedUrl";
import { getDataByBox } from "components/NineBox/functions";
import color from "theme/palette";
import defaultImg from "assets/images/general/avatar.png";
import {
  StyledCircleImage,
  StyledGroupNumber,
  StyledFocusRect,
  StyledTooltipRect,
  StyledTooltipText,
  StyledContainerList,
  StyledContentFlex,
  StyledIconButton,
  StyledCloseIcon,
  StyledListItemAvatar,
  StyledListItem,
  StyledSmallAvatar,
} from "../styles";

export const getEmployeeImage = (img) => (img && !isNullOrUndefined(img) ? img : defaultImg);

export const evaluationProcessDate = (date) => (date ? formatDateMonthYear(new Date(date)) : "");

export const getQuadrantData = (
  dataNinebox,
  quadrantResult,
  dataBox,
  t,
) => {
  // all quadrants
  let quadrantData = {
    results: dataNinebox,
    quadrant: t("ninebox:all_quadrant"),
    quadrantDescription: t("ninebox:text_details"),
    emptyDataMessage: "",
    details: t("ninebox:description"),
    quadrantNumber: 0,
  };

  if (quadrantResult) {
    quadrantData = {
      results: dataBox,
      quadrant: quadrantResult[NINEBOX.quadrantData.name],
      quadrantDescription: quadrantResult[NINEBOX.quadrantData.description],
      emptyDataMessage: t("ninebox:noDataQuadrant"),
      quadrantNumber: quadrantResult[NINEBOX.quadrantData.quadrantNumber],
    };
  }
  return quadrantData;
};

export const isDataNineBox = (dataNineBox) => dataNineBox
  && !isNullOrUndefined(dataNineBox.results);

export const starRatingAttr = (data, value) => (!isNullOrUndefined(data) ? data : value);

// Box range by scale
const getBoxValue = (maxValue, value) => getPercent(value / maxValue);

const getResultByType = (scaleIndices, type) => scaleIndices.filter(
  (index) => index.nine_box_result === type,
);

const getRangeByIndices = (scaleIndices) => {
  const maxValue = scaleIndices[0].top;
  const highResults = getResultByType(scaleIndices, OBJECT_KEYS.high);
  const mediumResults = getResultByType(scaleIndices, OBJECT_KEYS.medium);
  const lowResults = getResultByType(scaleIndices, OBJECT_KEYS.low);

  const getMaxValue = (results) => Math.max(...results.map((result) => result.top));

  const getMinValue = (results) => Math.min(...results.map((result) => result.bottom));

  const boxRange = {
    high: {
      min: highResults.length > 0 ? getBoxValue(maxValue, getMinValue(highResults)) : undefined,
      max: highResults.length > 0 ? getBoxValue(maxValue, getMaxValue(highResults)) : undefined,
    },
    medium: {
      min: mediumResults.length > 0 ? getBoxValue(maxValue, getMinValue(mediumResults)) : undefined,
      max: mediumResults.length > 0 ? getBoxValue(maxValue, getMaxValue(mediumResults)) : undefined,
    },
    low: {
      min: lowResults.length > 0 ? getBoxValue(maxValue, getMinValue(lowResults)) : undefined,
      max: lowResults.length > 0 ? getBoxValue(maxValue, getMaxValue(lowResults)) : undefined,
    },
  };

  return boxRange;
};

const getBoxRangeByResultScales = (resultScalePerformance, resultScalePotential) => {
  let boxRangePotential = 0;
  let boxRangePerformance = 0;

  if (resultScalePerformance && resultScalePotential) {
    boxRangePotential = getRangeByIndices(resultScalePotential);
    boxRangePerformance = getRangeByIndices(resultScalePerformance);
  }

  const boxRange = {
    xBox: boxRangePotential,
    yBox: boxRangePerformance,
  };

  return boxRange;
};

// Define an asynchronous function to load an image by its key
const loadImageByKey = async (imgKey) => {
  // Call the getImageURL function with the imgKey to retrieve the image URL
  const imageUrl = await getImageURL(imgKey);
  // Return the image URL
  return imageUrl;
};

// Define an asynchronous function to render an image or a placeholder
const renderImageOrPlaceholder = async (employee) => {
  // Initialize the imageUrl with the employee's img
  let imageUrl = employee.img;
  // Initialize the isLoading flag as true
  let isLoading = true;

  // Define an asynchronous function to load the image
  const loadImage = async () => {
    // Check if the employee has an imgKey
    if (employee.imgKey) {
      // Call the loadImageByKey function with the imgKey to load the image
      imageUrl = await loadImageByKey(employee.imgKey);
    }
    // Set the isLoading flag to false indicating that the image has been loaded
    isLoading = false;
  };

  // Call the loadImage function to load the image
  await loadImage();

  // Check if the image is still loading
  if (isLoading) {
    // Return a placeholder image element
    return (
      <image
        height={ NINEBOX.size }
        width={ NINEBOX.size }
        preserveAspectRatio={ NINEBOX.image.aspectRatio }
        xlinkHref={ defaultImg }
      />
    );
  }

  // Return the image elements
  return (
    <>
      <image
        height={ NINEBOX.size }
        width={ NINEBOX.size }
        preserveAspectRatio={ NINEBOX.image.aspectRatio }
        xlinkHref={ defaultImg }
      />
      <image
        height={ NINEBOX.size }
        width={ NINEBOX.size }
        preserveAspectRatio={ NINEBOX.image.aspectRatio }
        xlinkHref={ imageUrl }
        opacity={ 0 }
        onLoad={ (e) => {
          e.target.setAttribute("opacity", 1);
        } }
      />
    </>
  );
};

// Box data
export const getPatternImageByProcess = async (data) => {
  const patternImages = await Promise.all(data.map(renderImageOrPlaceholder));

  return (
    <defs>
      {data.map((employee, index) => (
        <pattern
          key={ employee.id }
          id={ `${NINEBOX.image.id}${employee.id}` }
          height={ NINEBOX.totalPercentage }
          width={ NINEBOX.totalPercentage }
          patternContentUnits={ PATTERN_OBJECT_BOUNDING }
        >
          {patternImages[index]}
        </pattern>
      ))}
    </defs>
  );
};

export const groupByPosition = (result) => reduce(
  result,
  (groups, item) => {
    const positions = `${item[NINEBOX.results.potential]}_${
        item[NINEBOX.results.performance]
      }`;
    groups[positions] = groups[positions] || {
      xPosition: item.potentialResult,
      yPosition: item.performanceResult,
      results: [],
      total: 0,
    };
    groups[positions].total += 1;
    groups[positions].results.push(item);
    return groups;
  },
  {},
);

const getMinValue = (total) => (gte(total, NINEBOX.group.max)
  ? NINEBOX.group.min
  : NINEBOX.group.initial);

const getTextPosition = (result, total) => ((total === undefined)
  ? result + NINEBOX.group.initial
  : result - getMinValue(total));

export const getPositionCy = (performance) => (performance - 100) * -1;

// Object with the corresponding values in the graphic for each limit in axisLimits
export const axisGraphic = {
  high: {
    min: 66.66666666666667,
    max: 100,
  },
  medium: {
    min: 33.333333333333336,
    max: 66.66666666666667,
  },
  low: {
    min: 0,
    max: 33.333333333333336,
  },
};

// Function to calculate the position of a point in the quadrant
export const getQuadrantPosition = (xPosition, yPosition, quadrantData) => {
  if (!quadrantData || !Object.keys(quadrantData).length) {
    return {};
  }
  // Extract scale data for x and y axes
  const xScale = quadrantData[NINEBOX.quadrantData.scale].xBox;
  const yScale = quadrantData[NINEBOX.quadrantData.scale].yBox;

  // Find the appropriate x range based on xPosition
  const xRange = Object.keys(xScale).find(
    (rangeName) => xPosition >= xScale[rangeName].min && xPosition <= xScale[rangeName].max,
  );

  // Find the appropriate y range based on yPosition
  const yRange = Object.keys(yScale).find(
    (rangeName) => yPosition >= yScale[rangeName].min && yPosition <= yScale[rangeName].max,
  );

  // Calculate x position within the range as a percentage
  const xPercentage = (xPosition - xScale[xRange].min) / (xScale[xRange].max - xScale[xRange].min);

  // Get SVG range for x based on the range name
  const svgXRange = axisGraphic[xRange];

  // Calculate actual SVG x position using the percentage and SVG range
  const svgX = xPercentage * (svgXRange.max - svgXRange.min) + svgXRange.min;

  // Calculate y position within the range as a percentage
  const yPercentage = (yPosition - yScale[yRange].min) / (yScale[yRange].max - yScale[yRange].min);

  // Get SVG range for y based on the range name
  const svgYRange = axisGraphic[yRange];

  // Calculate actual SVG y position using the percentage and SVG range
  const svgY = yPercentage * (svgYRange.max - svgYRange.min) + svgYRange.min;

  // Calculate total y position with any additional adjustments (e.g., getPositionCy)
  const yTotal = getPositionCy(svgY);

  // Return the calculated positions for x and y within the SVG quadrant
  const result = { xPosition: svgX, yPosition: yTotal };
  return result;
};

const positionResult = (value) => value - NINEBOX.focus.position;

export const getCircleImage = (
  order,
  total,
  quadrantData,
  xPosition,
  yPosition,
  url,
  circleRef,
  focus,
) => {
  const result = getQuadrantPosition(xPosition, yPosition, quadrantData);
  return (
    <>
      {focus && (
        <>
          <StyledFocusRect
            x={ positionResult(result.xPosition) }
            y={ NINEBOX.zero }
            width={ NINEBOX.focus.width }
            height={ NINEBOX.total }
            fill={ color.white }
          />
          <StyledFocusRect
            x={ NINEBOX.zero }
            y={ positionResult(result.yPosition) }
            width={ NINEBOX.total }
            height={ NINEBOX.focus.width }
            fill={ color.white }
          />
        </>
      )}
      <StyledCircleImage
        ref={ circleRef && circleRef }
        r={ CIRCLE_SIZE[order] || NINEBOX.radius.max }
        cx={ result.xPosition }
        cy={ result.yPosition }
        order={ order }
        fill={ url || color.white }
      />
      {gte(total, NINEBOX.group.min) && (
        <StyledGroupNumber
          x={ getTextPosition(result.xPosition, total) }
          y={ getTextPosition(result.yPosition) }
        >
          {total}
          {gte(total, NINEBOX.group.max) && "+"}
        </StyledGroupNumber>
      )}
    </>
  );
};

export const getTooltipName = (name, circleRef) => (
  <Tooltip triggerRef={ circleRef }>
    <StyledTooltipRect
      x={ NINEBOX.tooltip.rect.x }
      y={ NINEBOX.tooltip.rect.y }
      width={ name.length + NINEBOX.tooltip.rect.width }
      height={ NINEBOX.tooltip.rect.height }
      rx={ NINEBOX.tooltip.rect.rx }
      ry={ NINEBOX.tooltip.rect.ry }
    />
    <StyledTooltipText
      x={ NINEBOX.tooltip.text.x }
      y={ NINEBOX.tooltip.text.y }
    >
      {name}
    </StyledTooltipText>
  </Tooltip>
);

export const getCircleByGroup = (
  data,
  handleEmployeeList,
  handleCircleSelected,
  circleRef,
  order = null,
  isActive = true,
) => {
  const dataGroup = groupByPosition(data);
  return Object.keys(dataGroup).map((key) => {
    const result = dataGroup[key].results[NINEBOX.group.zero];
    if (isActive) {
      return dataGroup[key].total !== NINEBOX.group.initial ? (
        <g
          key={ `${key}-${result.id}` }
          onClick={ (e) => handleEmployeeList(e, dataGroup[key].results) }
        >
          {getCircleImage(
            order,
            dataGroup[key].total,
            result.quadrantData,
            dataGroup[key].xPosition,
            dataGroup[key].yPosition,
          )}
        </g>
      ) : (
        <g
          key={ result.id }
          onClick={ () => handleCircleSelected(result) }
        >
          {getCircleImage(
            order,
            NINEBOX.group.initial,
            result.quadrantData,
            result.potentialResult,
            result.performanceResult,
          )}
          {getCircleImage(
            order,
            NINEBOX.group.initial,
            result.quadrantData,
            result.potentialResult,
            result.performanceResult,
            `url(#${NINEBOX.image.id}${result.id})`,
            circleRef,
          )}
          {result.name && getTooltipName(result.name, circleRef)}
        </g>
      );
    }
    return [];
  });
};

// Quadrant
export const isDataOfQuadrant = (
  potentialResult,
  performanceResult,
  quadrantResult,
) => (
  lte(potentialResult, quadrantResult[NINEBOX.quadrantData.xMax])
    && gte(potentialResult, quadrantResult[NINEBOX.quadrantData.xMin])
    && lte(performanceResult, quadrantResult[NINEBOX.quadrantData.yMax])
    && gte(performanceResult, quadrantResult[NINEBOX.quadrantData.yMin])
);

const getQuadrant = (
  performanceResult,
  potentialResult,
  dataNineBox,
  t,
  isName,
) => {
  let scaleResult = isName ? "" : {};
  dataNineBox.forEach((quadrantResult) => {
    if (isDataOfQuadrant(potentialResult, performanceResult, quadrantResult)) {
      scaleResult = isName
        ? `${t("ninebox:quadrant")}: ${
            quadrantResult[NINEBOX.quadrantData.name]
          }`
        : quadrantResult;
    }
  });
  return scaleResult;
};

export const getResult = (score, top) => score / top;

export const getFilteredData = (dataNineBox, quadrantResult) => {
  const { quadrant } = NINEBOX.quadrantData;
  return dataNineBox.filter((item) => item.quadrantData[quadrant] === quadrantResult[quadrant]);
};

// employees with equal nine box position
export const getDataList = (
  handleEmployeeList,
  employeeGroup,
  handleCircleAndReset,
) => (
  <StyledContainerList>
    <StyledContentFlex>
      <StyledIconButton
        aria-label={ ACTIONS.close }
        onClick={ handleEmployeeList }
      >
        <StyledCloseIcon fontSize={ SIZE.small } />
      </StyledIconButton>
    </StyledContentFlex>
    {employeeGroup?.map((result) => (
      <StyledListItem
        key={ result.id }
        divider
        button
        onClick={ (e) => handleCircleAndReset(e, result) }
      >
        <StyledListItemAvatar>
          <StyledSmallAvatar
            alt={ result.name }
            src={ result.img }
          />
        </StyledListItemAvatar>
        <ListItemText primary={ result.name } />
      </StyledListItem>
    ))}
  </StyledContainerList>
);

// Create data ninebox
export const getDataNineBoxResult = async (talentScores, resultScaleList, t, quadrants) => new Promise((resolve) => {
  const dataNineBoxResult = [];
  if (talentScores && resultScaleList) {
    talentScores.forEach(async (talentScoreEmployee, index) => {
      // TODO: change and use position (x, y) - endpoint value
      const resultScalePerformanceId = talentScoreEmployee.performance?.process_result_scale_id;
      const resultScalePotentialId = talentScoreEmployee.potential?.process_result_scale_id;
      const resultScalePerformance = resultScaleList.find(
        (scale) => scale.id === resultScalePerformanceId,
      );
      const resultScalePotential = resultScaleList.find(
        (scale) => scale.id === resultScalePotentialId,
      );
      const performanceIndices = resultScalePerformance?.result_scale_indices;
      const potentialIndices = resultScalePotential?.result_scale_indices;

      const performanceScale = orderByDesc(performanceIndices, NINEBOX.resultScale.top);
      const potentialScale = orderByDesc(potentialIndices, NINEBOX.resultScale.top);
      const boxRangeByScales = getBoxRangeByResultScales(performanceScale, potentialScale);

      if (boxRangeByScales && talentScoreEmployee.performance !== null
          && talentScoreEmployee.potential !== null) {
        // TODO: change by endpoint value
        const performanceResult = getPercent(
          getResult(
            talentScoreEmployee.performance.score,
            performanceScale[0].top,
          ),
          false,
        );
        const potentialResult = getPercent(
          getResult(
            talentScoreEmployee.potential.score,
            potentialScale[0].top,
          ),
          false,
        );
        const evaluationDates = {
          performanceDate: evaluationProcessDate(
            talentScoreEmployee.performance.process_end_date,
          ),
          potentialDate: evaluationProcessDate(
            talentScoreEmployee.potential.process_end_date,
          ),
        };

        const performancePosition = findDataInScale(
          performanceIndices,
          talentScoreEmployee.performance.score,
          false,
        );
        const potentialPosition = findDataInScale(
          potentialIndices,
          talentScoreEmployee.potential.score,
          false,
        );

        dataNineBoxResult.push({
          index: index + 1,
          talentScoreId: talentScoreEmployee.id,
          id: talentScoreEmployee.employee.id,
          name: talentScoreEmployee.employee.full_name,
          email: talentScoreEmployee.employee.email,
          img: talentScoreEmployee.employee.profile_img_url || defaultImg,
          imgKey: talentScoreEmployee.employee?.profile_img_key,
          performanceResult,
          performanceResultText: talentScoreEmployee.performance.scored_result,
          potentialResult,
          potentialResultText: talentScoreEmployee.potential.scored_result,
          hasManagerEvaluationAnswered: talentScoreEmployee.has_manager_evaluation_answered,
          quadrantData: getQuadrant(
            performanceResult,
            potentialResult,
            getDataByBox(quadrants, boxRangeByScales),
          ),
          process: {
            evaluationDates,
          },
          boxRangeByScales,
          performancePosition,
          potentialPosition,
        });
      }
      if (index === (talentScores.length - 1)) {
        resolve(dataNineBoxResult);
      }
    });
  }
});

export const downloadExcel = (filename, nineBoxResult, t) => () => {
  const data = [];
  nineBoxResult.map((result) => data.push({
    [t("ninebox:table-head-download.id")]: result.id,
    [t("ninebox:table-head-download.name")]: result.name,
    [t("ninebox:table-head-download.email")]: result.email,
    [t(
      "ninebox:table-head-download.performanceScore",
    )]: result.performanceResult,
    [t(
      "ninebox:table-head-download.performanceResult",
    )]: result.performanceResultText,
    [t(
      "ninebox:table-head-download.potentialScore",
    )]: result.potentialResult,
    [t(
      "ninebox:table-head-download.potentialResult",
    )]: result.potentialResultText,
    [t("ninebox:table-head-download.quadrant")]: result.quadrantData[
      NINEBOX.quadrantData.name
    ],
  }));
  return [data, filename];
};

// Individual results
export const getCircleByProfile = (
  data,
) => {
  const dataGroup = groupByPosition(data);
  return Object.keys(dataGroup).map((key, index) => {
    const result = dataGroup[key].results[NINEBOX.group.zero];
    return (
      <g
        key={ result.id }
      >
        {getCircleImage(
          index,
          NINEBOX.group.initial,
          result.quadrantData,
          result.potentialResult,
          result.performanceResult,
          `url(#${NINEBOX.image.id}${result.id})`,
        )}
      </g>
    );
  });
};

export const getDataNineBoxQuadrant = (talentScoreEmployee, resultScaleList, t, quadrants) => {
  const dataNineBoxResult = [];
  if (talentScoreEmployee && resultScaleList) {
    const resultScalePerformanceId = talentScoreEmployee.performance?.process_result_scale_id;
    const resultScalePotentialId = talentScoreEmployee.potential?.process_result_scale_id;
    const resultScalePerformance = resultScaleList.find(
      (scale) => scale.id === resultScalePerformanceId,
    );
    const resultScalePotential = resultScaleList.find(
      (scale) => scale.id === resultScalePotentialId,
    );
    const performanceIndices = resultScalePerformance?.result_scale_indices;
    const potentialIndices = resultScalePotential?.result_scale_indices;

    const performanceScale = orderByDesc(performanceIndices, NINEBOX.resultScale.top);
    const potentialScale = orderByDesc(potentialIndices, NINEBOX.resultScale.top);
    const boxRangeByScales = getBoxRangeByResultScales(performanceScale, potentialScale);

    if (boxRangeByScales && talentScoreEmployee.performance !== null
          && talentScoreEmployee.potential !== null) {
      const performanceResult = getPercent(
        getResult(
          talentScoreEmployee.performance.score,
          performanceScale[0].top,
        ),
        false,
      );
      const potentialResult = getPercent(
        getResult(
          talentScoreEmployee.potential.score,
          potentialScale[0].top,
        ),
        false,
      );
      dataNineBoxResult.push({
        quadrantData: getQuadrant(
          performanceResult,
          potentialResult,
          getDataByBox(quadrants, boxRangeByScales),
        ),
      });
    }
  }
  return dataNineBoxResult;
};

export const calculateTotal = (quadrantNumber, quadrants, isAllData) => {
  if (quadrants) {
    if (quadrantNumber === 0) {
      return Object.values(quadrants).reduce((acc, quadrant) => {
        if (isAllData) {
          return acc + quadrant.total;
        }
        return acc + quadrant.total_manager_evaluation_answered;
      }, 0);
    }

    if (quadrantNumber > 0) {
      const selectedQuadrant = quadrants[quadrantNumber]
    || { total: 0, total_manager_evaluation_answered: 0 };

      if (isAllData) {
        return selectedQuadrant.total;
      }
      return selectedQuadrant.total_manager_evaluation_answered;
    }
  }
  return 0;
};

export const calculateTotalByQuadrants = (quadrants, isAllData) => {
  const total = Object.keys(quadrants).reduce((sum, key) => {
    const quadrant = quadrants[key];
    return sum + (isAllData ? quadrant.total : quadrant.total_manager_evaluation_answered);
  }, 0);

  const quadrantInfo = {
    1: { totalCollaborators: 0, percentage: 0 },
    2: { totalCollaborators: 0, percentage: 0 },
    3: { totalCollaborators: 0, percentage: 0 },
    4: { totalCollaborators: 0, percentage: 0 },
    5: { totalCollaborators: 0, percentage: 0 },
    6: { totalCollaborators: 0, percentage: 0 },
    7: { totalCollaborators: 0, percentage: 0 },
    8: { totalCollaborators: 0, percentage: 0 },
    9: { totalCollaborators: 0, percentage: 0 },
  };

  Object.keys(quadrants).forEach((key) => {
    const quadrant = quadrants[key];
    const totalCollaborators = isAllData
      ? quadrant.total : quadrant.total_manager_evaluation_answered;
    const percentage = total > 0 ? (totalCollaborators / total) * 100 : 0;

    quadrantInfo[key] = {
      totalCollaborators,
      percentage: Math.round(percentage),
    };
  });

  return quadrantInfo;
};
