import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "components/Accordion";
import Button from "components/Button";
import NoDataMessage from "components/NoDataMessage";
import defaultImg from "assets/images/general/avatar.png";
import {
  BORDER_ACCORDION_TYPE,
  BUTTON_STYLE_TYPES,
  VARIANT,
} from "common/constants";
import { isNullOrUndefined } from "common/helpers";
import { getCurrentLanguage } from "common/utils";
import { getImageURL } from "helpers/presignedUrl";
import { useNineBoxTypes } from "hooks/useNineBoxType";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import { isDataNineBox, calculateTotal } from "../../functions";
import {
  StyledDescription,
  StyledQuadrantDescription,
  StyledHistorical,
} from "./styles";
import NineBoxTypeForm from "../NineBoxTypeForm";

const Historical = (props) => {
  const {
    t,
    data,
    nineboxStates,
    resetQuadrants,
    handleSelectedPanel,
    quadrants,
    isAllData,
    isAdmin,
  } = props;

  const language = getCurrentLanguage();

  const [historical, setHistorical] = useState([]);
  const [editNineBox, setEditNineBox] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [quadrantData, setQuadrantData] = useState({});

  const { nineBoxTypeList, isLoading } = useNineBoxTypes(t);

  const handleCancelEdit = (updatedNineBoxType = null) => {
    if (updatedNineBoxType) {
      const updatedData = {
        ...data,
        quadrantDescription: updatedNineBoxType.description,
        quadrant: updatedNineBoxType.name,
      };
      setQuadrantData(updatedData);
    }

    setIsChecked(false);
    setEditNineBox(false);
  };

  const getData = useCallback(async () => {
    const historicalResult = [];
    if (!isNullOrUndefined(data.results)) {
      await Promise.all(
        data.results.map(async (result) => {
          if (result.id) {
            let profileImage = result?.img || defaultImg;
            if (result?.imgKey) {
              try {
                profileImage = await getImageURL(result.imgKey);
              } catch (error) {
                profileImage = defaultImg;
              }
            }
            historicalResult.push({
              src: profileImage,
              title: result.name,
              customRender: result.historical || <div data={ result }>{""}</div>,
            });
          }
        }),
      );
    }
    setHistorical(historicalResult);
  }, [quadrantData]);

  useEffect(() => {
    setIsChecked(false);
    setEditNineBox(false);
    setQuadrantData(data);
    if (isDataNineBox(data)) {
      getData();
    }
    // eslint-disable-next-line
  }, [data]);

  const handleCheckboxChange = (event) => {
    const isEditChecked = event.target.checked;
    setIsChecked(isEditChecked);

    if (isEditChecked && quadrantData.quadrantNumber > 0) {
      const selectedNineBox = nineBoxTypeList?.find(
        (box) => box.quadrant_number === quadrantData.quadrantNumber,
      );
      setEditNineBox(selectedNineBox || false);
    } else {
      setEditNineBox(false);
    }
  };

  const selectedNineBox = quadrantData.quadrantNumber > 0
    ? nineBoxTypeList?.find(
      (box) => box.quadrant_number === quadrantData.quadrantNumber,
    )
    : null;

  return (
    <>
      <StyledDescription square>
        {quadrantData?.quadrantNumber > 0 && selectedNineBox ? (
          <>
            <Typography variant={ VARIANT.h4 }>
              {`${calculateTotal(quadrantData?.quadrantNumber, quadrants, isAllData)} ${t("ninebox:historical_total")}`}
            </Typography>
            <Typography variant={ VARIANT.h6 }>
              {`${t("ninebox:quadrant")}: ${quadrantData?.quadrantNumber}. ${selectedNineBox[`name_${language}`] || ""}`}
            </Typography>
            <StyledQuadrantDescription variant={ VARIANT.body1 }>
              {selectedNineBox[[`description_${language}`]]}
            </StyledQuadrantDescription>
          </>
        ) : (
          <>
            <Typography variant={ VARIANT.h4 }>
              {`${calculateTotal(quadrantData?.quadrantNumber, quadrants, isAllData)} ${t("ninebox:historical_total")}`}
            </Typography>
            <Typography variant={ VARIANT.h6 }>
              {`${t("ninebox:quadrant")}: ${quadrantData?.quadrant || ""}`}
            </Typography>
            <StyledQuadrantDescription variant={ VARIANT.body1 }>
              {quadrantData?.quadrantDescription || ""}
            </StyledQuadrantDescription>
          </>
        )}
        {quadrantData?.details && (
          <StyledQuadrantDescription variant={ VARIANT.body1 }>
            {quadrantData?.details}
          </StyledQuadrantDescription>
        )}
        <Button
          variant={ VARIANT.contained }
          typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
          isDisabled={ quadrantData?.quadrantNumber === 0 || editNineBox }
          onClick={ resetQuadrants }
        >
          {t("ninebox:view_all")}
        </Button>
        {quadrantData?.quadrantNumber > 0 && isAdmin && (
          <>
            <FormControlLabel
              disabled={ isLoading }
              control={ <Checkbox checked={ isChecked } onChange={ handleCheckboxChange } /> }
              label={ t("ninebox:quadrant_form.title") }
            />
            {editNineBox && (
              <NineBoxTypeForm
                t={ t }
                nineBoxType={ editNineBox }
                handleCancelEdit={ handleCancelEdit }
              />
            )}
          </>
        )}
      </StyledDescription>
      {!editNineBox && (historical.length > 0 ? (
        <StyledScrollBar maxHeight={ "400px" }>
          <StyledHistorical>
            <Accordion
              isPadding
              isDefaultIconStyle
              type={ BORDER_ACCORDION_TYPE.square }
              data={ historical }
              activePanel={ nineboxStates.circleSelected }
              accordionSelected={ nineboxStates.accordionSelected }
              setSelectedPanel={ handleSelectedPanel }
            />
          </StyledHistorical>
        </StyledScrollBar>
      ) : (
        <NoDataMessage
          message={ quadrantData?.emptyDataMessage || t("ninebox:historical_empty") }
        />
      ))}
    </>
  );
};

Historical.propTypes = {
  t: PropTypes.func.isRequired,
  nineboxStates: PropTypes.object.isRequired,
  getQuadrantDetail: PropTypes.func.isRequired,
  resetQuadrants: PropTypes.func.isRequired,
  handleSelectedPanel: PropTypes.func.isRequired,
  data: PropTypes.object,
  quadrants: PropTypes.object,
  isAllData: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

Historical.defaultProps = {
  data: {},
  quadrants: null,
  isAllData: true,
  isAdmin: false,
};

export default Historical;
