import {
  createRef, useEffect, useCallback,
} from "react";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import NineBox from "components/NineBox";
import SimpleAlert from "components/SimpleAlert";
import Alert from "components_refactor/Alert";
import SwitchSelectorContent from "components/SwitchSelectorContent";
import { NINEBOX } from "common/constants";
import { TRANSITIONS, MATRIX_SWITCH_OPTIONS } from "common/constants/ninebox";
import {
  isNullOrUndefined,
  isEmpty,
  isNull,
  isNotValid,
} from "common/helpers";
import { switchOptions, isValidAdmin } from "common/utils";
import { usePatternImageNineBox } from "hooks/usePatternImageNineBox";
import { getNineBoxList as getCurrentTalentScore } from "redux/actions/talentScore/talentScoreActions";
import { useNineBoxTypes } from "hooks/useNineBoxType";
import Historical from "../Historical";
import HistoricalHeader from "../HistoricalHeader";
import MatrixSmallDataSet from "./MatrixSmallDataSet";
import HistoricalSmallDataSet from "../Historical/HistoricalSmallDataSet";
import {
  getQuadrantData,
  getCircleByGroup,
  getTooltipName,
  getCircleImage,
  getDataList,
  getDataNineBoxResult,
} from "../../functions";
import {
  StyledNineboxMatrix,
  StyledNineboxContent,
  StyledCard,
  StyledGlobalActions,
  StyledOptionDescription,
} from "./styles";

const Matrix = (props) => {
  const {
    t,
    nineboxStates,
    getQuadrantDetail,
    handleSelectedQuadrant,
    handleEmployeeList,
    handleCircleSelected,
    handleCircleAndReset,
    resultScaleReducerStates,
    resetQuadrants,
    setNineboxStates,
    handleChangeDataByOption,
    user,
    dispatch,
  } = props;

  const {
    query,
    nineBoxResult,
    selectedPanel,
    selectedQuadrant,
    quadrantResult,
    dataBox,
    anchorEl,
    employeeGroup,
    data,
    optionSelected,
  } = nineboxStates;
  const { isLoadingScaleList, resultScaleList } = resultScaleReducerStates;

  const isAdmin = isValidAdmin(user?.userCookies);

  // TODO: Check the filter component because the current one generates
  // different queries when nothing is selected.
  let cleanedQuery = {};
  if (query) {
    cleanedQuery = {
      q: Object.fromEntries(
        Object.entries(query.q).filter(([, value]) => !isNull(value)),
      ),
    };
  }

  const {
    data: { data: currentTalentScores, total, quadrants } = {},
    isLoading: isLoadingCurrentTalentScores,
    error,
  } = useQuery(["currentTalentScores", cleanedQuery], () => getCurrentTalentScore(cleanedQuery, 1), {
    staleTime: Infinity,
    enabled: true,
  });

  const { nineBoxTypeList, isLoading } = useNineBoxTypes(t);

  const setResults = useCallback(async () => {
    resetQuadrants();
    setNineboxStates({ nineBoxResult: [] });

    if (!isNotValid(currentTalentScores) && !isNotValid(resultScaleList)) {
      const results = await getDataNineBoxResult(currentTalentScores, resultScaleList, t, nineBoxTypeList);
      const filteredDataByManagerAnswered = results.filter((result) => result.hasManagerEvaluationAnswered);
      setTimeout(() => {
        setNineboxStates({
          nineBoxResult: results,
          filteredData: filteredDataByManagerAnswered,
          optionSelected: optionSelected !== null ? optionSelected : MATRIX_SWITCH_OPTIONS.manager,
          data: optionSelected === MATRIX_SWITCH_OPTIONS.all ? results : filteredDataByManagerAnswered,
        });
      }, 200);
    }
  }, [
    currentTalentScores,
    resetQuadrants,
    resultScaleList,
    t,
    setNineboxStates,
    optionSelected,
  ]);

  useEffect(() => {
    if (!isLoadingCurrentTalentScores && !isLoadingScaleList && !isLoading && currentTalentScores) {
      setResults();
    }
    // eslint-disable-next-line
  }, [
    currentTalentScores,
    isLoadingCurrentTalentScores,
    isLoadingScaleList,
  ]);

  const circleRef = createRef();

  const isValidData = (!isNullOrUndefined(selectedPanel?.quadrantData)
  && !isNullOrUndefined(selectedPanel?.potentialResult)
  && !isNullOrUndefined(selectedPanel?.performanceResult));

  // Switch options
  const options = [
    switchOptions(t("ninebox:switch.manager"), MATRIX_SWITCH_OPTIONS.manager),
    switchOptions(t("ninebox:switch.all"), MATRIX_SWITCH_OPTIONS.all),
  ];

  const handleChangeSwitchOption = (option) => {
    handleChangeDataByOption(option);
  };

  const patternImage = usePatternImageNineBox(nineBoxResult);

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 }>
        {(error && error?.response?.status && !isLoadingCurrentTalentScores)
        && (
          <SimpleAlert
            type={ "error" }
            title={ t("common:common.api_responses.error.title") }
            message={ t(`common:common.api_responses.error.${error.response.status}`) }
          />
        )}
      </Grid>
      <Grid item xs={ 12 } md={ 7 }>
        <StyledCard>
          {isLoadingCurrentTalentScores && (
            <Alert
              type={ "info" }
              title={ t("common:general_alert.info.title") }
              message={ t("common:general_alert.info.message") }
            />
          )}
          { total && total > 100
            ? (
              <MatrixSmallDataSet
                t={ t }
                nineboxStates={ nineboxStates }
                getQuadrantDetail={ getQuadrantDetail }
                handleSelectedQuadrant={ handleSelectedQuadrant }
                handleChangeDataByOption={ handleChangeDataByOption }
                resultScaleList={ resultScaleList }
                quadrants={ quadrants }
                isAllData={ optionSelected === MATRIX_SWITCH_OPTIONS.all }
              />
            )
            : (
              <StyledNineboxMatrix>
                <Grid container>
                  <Grid item xs={ 12 } md={ 5 } />
                  <Grid item xs={ 12 } md={ 7 }>
                    <SwitchSelectorContent
                      options={ options }
                      hasTitle={ false }
                      onChangeOption={ handleChangeSwitchOption }
                      isFull
                    />
                  </Grid>
                  <Grid item xs={ 12 } md={ 1 } />
                  <Grid item xs={ 12 } md={ 11 }>
                    <StyledOptionDescription variant={ "body1" }>
                      {t(`ninebox:switch.${optionSelected === MATRIX_SWITCH_OPTIONS.all ? "allDescription" : "managerDescription" }`)}
                    </StyledOptionDescription>
                  </Grid>
                </Grid>
                <NineBox
                  getQuadrantDetail={ getQuadrantDetail }
                  quadrant={ selectedQuadrant }
                  setSelectedQuadrant={ handleSelectedQuadrant }
                >
                  {/* circle patterns */}
                  {patternImage !== "" && patternImage}
                  {/* circle details */}
                  {(!isLoadingCurrentTalentScores || data) && getCircleByGroup(
                    data,
                    handleEmployeeList,
                    handleCircleSelected,
                    circleRef,
                    0, // current results
                  )}
                  {/* actions on matrix  */}
                  {!isNullOrUndefined(selectedPanel)
                    && !isEmpty(selectedPanel) && (
                    <StyledGlobalActions
                      onClick={ () => handleCircleSelected(selectedPanel) }
                    >
                      {isValidData
                        && getCircleImage(
                          null,
                          NINEBOX.group.initial,
                          selectedPanel.quadrantData,
                          selectedPanel.potentialResult,
                          selectedPanel.performanceResult,
                          `url(#${NINEBOX.image.id}${selectedPanel.id})`,
                          circleRef,
                          true,
                        )}
                      {selectedPanel.name
                        && getTooltipName(selectedPanel.name, circleRef)}
                    </StyledGlobalActions>
                  )}
                </NineBox>
              </StyledNineboxMatrix>
            )}
        </StyledCard>
      </Grid>
      <Grid item xs={ 12 } md={ 5 }>
        <StyledCard>
          <StyledNineboxContent>
            <HistoricalHeader
              t={ t }
              nineBoxResult={ data }
              isSmallDataSet={ total && total > 100 }
            />
            {total && total > 100
              ? (
                <HistoricalSmallDataSet
                  t={ t }
                  quadrantResult={ quadrantResult }
                  quadrants={ quadrants }
                  isAllData={ optionSelected === MATRIX_SWITCH_OPTIONS.all }
                  isAdmin={ isAdmin }
                  cleanedQuery={ cleanedQuery }
                  resultScaleList={ resultScaleList }
                  dispatch={ dispatch }
                  { ...props }
                />
              )
              : (
                <>
                  <Historical
                    data={ getQuadrantData(
                      data,
                      quadrantResult,
                      dataBox,
                      t,
                    ) }
                    quadrants={ quadrants }
                    isAllData={ optionSelected === MATRIX_SWITCH_OPTIONS.all }
                    isAdmin={ isAdmin }
                    { ...props }
                  />
                  <Popper
                    id={ anchorEl && TRANSITIONS.popper }
                    open={ !isNull(anchorEl) }
                    anchorEl={ anchorEl }
                  >
                    <Paper elevation={ 3 }>
                      {getDataList(
                        handleEmployeeList,
                        employeeGroup,
                        handleCircleAndReset,
                      )}
                    </Paper>
                  </Popper>
                </>
              )}
          </StyledNineboxContent>
        </StyledCard>
      </Grid>
    </Grid>
  );
};

Matrix.propTypes = {
  t: PropTypes.func.isRequired,
  nineboxStates: PropTypes.object.isRequired,
  getQuadrantDetail: PropTypes.func.isRequired,
  handleSelectedQuadrant: PropTypes.func.isRequired,
  handleEmployeeList: PropTypes.func.isRequired,
  handleCircleSelected: PropTypes.func.isRequired,
  handleCircleAndReset: PropTypes.func.isRequired,
  resultScaleReducerStates: PropTypes.object.isRequired,
  resetQuadrants: PropTypes.func.isRequired,
  setNineboxStates: PropTypes.func.isRequired,
  handleExternalQuery: PropTypes.func.isRequired,
  handleChangeDataByOption: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Matrix;
