import PropTypes from "prop-types";
import DownloadExcelButton from "components/DownloadExcelButton";
import { VARIANT } from "common/constants";
import DataNineBoxIcon from "assets/images/ninebox/bill.svg";
import { downloadExcel } from "../../functions";
import {
  StyledTitle,
  StyledImage,
  StyledNineboxTitle,
  StyledHeader,
} from "./styles";

const HistoricalHeader = (props) => {
  const { t, nineBoxResult, isSmallDataSet } = props;

  return (
    <StyledHeader>
      <StyledNineboxTitle>
        <StyledImage src={ DataNineBoxIcon } alt={ "Ninebox" } />
        <StyledTitle variant={ VARIANT.h4 }>{t("ninebox:title")}</StyledTitle>
      </StyledNineboxTitle>
      {!isSmallDataSet
      && (
        <DownloadExcelButton
          onClick={ downloadExcel(
            t("ninebox:title"),
            nineBoxResult,
            t,
          ) }
          isDisabled={ nineBoxResult?.length === 0 || nineBoxResult === null }
        />
      )}
    </StyledHeader>
  );
};

HistoricalHeader.propTypes = {
  t: PropTypes.func.isRequired,
  nineBoxResult: PropTypes.array.isRequired,
  isSmallDataSet: PropTypes.bool,
};

HistoricalHeader.defaultProps = {
  isSmallDataSet: true,
};

export default HistoricalHeader;
