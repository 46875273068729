import Box from "@mui/material/Box";
import styled from "styled-components";
import palette from "theme/palette";

export const StyledProgress = styled.div`
  position: relative;
  display: inline-flex;
  .MuiCircularProgress-root {
    border-radius: 100%;
    background-color: rgba(255,255,255,0.5);
  }
  .MuiBox-root {
    .MuiTypography-caption {
      font-weight:700;
      line-height: 0px;
      letter-spacing: 0px;
    }
  }
`;

export const StyledBox = styled(Box)`
  padding: 0px !important;
  background-color: rgba(255,255,255,0.5);
  border-radius: 10px;
  .MuiBox-root {
    padding: 0px !important;
  }
  .MuiTypography-body2 {
    font-weight: 700;
  }
  .MuiCircularProgress-determinate {
    color: ${(props) => (props.active ? palette.background.purple : palette.primaryApp)};
  }
`;
